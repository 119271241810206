import { BaseStepIdEnum, StepConfig } from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: BaseStepIdEnum.affiliated_companies_list,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: true },
  },
];
